<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
    onsubmit="return false;"
  >
    <v-container fluid row justify-end>
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        v-show="x.loading"
      ></v-progress-linear>
      <v-card raised>
        <v-card-title primary-title class="bg-clr">
          <v-btn fab flat icon class="nospace" @click.native="close">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <span class="headline">&nbsp;New Transporter</span>
        </v-card-title>
        <v-card-text class="content-border">
          <div class="heading primary-clr pt-3">Company Details</div>
          <v-layout row pt-2>
            <v-flex xs12 md6>
              <div v-if="profilePicURL" class="subheading muted caption">
                Profile Image
              </div>
              <a
                v-if="profilePicURL"
                :href="profilePicURL"
                title="Profile Image"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="profilePicURL"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <v-radio-group
                class="mt-0 pt-0"
                v-model="type"
                row
                single-line
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Registering As</div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="Transporter"
                        value="Transporter"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Broker"
                        value="Broker"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 md2></v-flex>

            <v-flex xs12 md4>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Profile Image</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileUpload"
                  accept=".pdf, .jpeg, .jpg, .png"
                  @change="profileImageChange"
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Name of Company*</div>
              <div class="heading">
                <v-text-field
                  label="Name of Company *"
                  v-model="companyName"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                />
              </div>
            </v-flex>
            <v-flex xs12 sm6 md2></v-flex>
            <v-flex xs12 sm6 md5>
              <div class="upload-btn-wrapper">
                <button class="btn">
                  {{
                    countrySortNames != "PAK"
                      ? "Upload Trade License/CR Copy"
                      : "Upload NTN Certificate"
                  }}
                </button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileUpload"
                  accept=".pdf, .jpeg, .jpg, .png .pdf"
                  @change="tradeLicenseChange"
                />
              </div>
              <div v-if="tradeLicense" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="tradeLicense">
                  <button type="button" class="m-10 outline-border">
                    {{
                      countrySortNames != "PAK"
                        ? "View Trade License/CR Copy"
                        : "View NTN Certificate"
                    }}
                  </button>
                </a>
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Username*</div>
              <div class="heading">
                <v-text-field
                  label="Username *"
                  v-model="username"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  required
                  maxlength="90"
                />
              </div>
            </v-flex>

            <v-flex xs12 sm6 md2></v-flex>
            <v-flex xs12 sm6 md4>
              <div class="upload-btn-wrapper">
                <button class="btn">
                  {{
                    countrySortNames != "PAK"
                      ? "Upload VAT Certificate"
                      : "Upload STRN Certificate"
                  }}
                </button>
                <input
                  accept=".pdf, .jpeg, .jpg, .png"
                  type="file"
                  name="myfile"
                  @change="vatCertificateChange"
                />
              </div>
              <div v-if="vatCertificate" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="vatCertificate">
                  <button type="button" class="m-10 outline-border">
                    {{
                      countrySortNames != "PAK"
                        ? "View VAT Certificate"
                        : "View STRN Certificate"
                    }}
                  </button>
                </a>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Email Id*</div>
              <div class="heading">
                <v-text-field
                  label="Email Id *"
                  :rules="[rules.emailRules, rules.required]"
                  v-model="emailId"
                  single-line
                  class="pt-0 currencyTitle"
                  required
                />
              </div>
            </v-flex>

            <v-flex xs12 sm6 md2></v-flex>
            <v-flex xs12 sm6 md3 v-if="this.countrySortNames != 'PAK'">
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Passport Front Image</button>
                <input
                  type="file"
                  accept=".pdf, .jpeg, .jpg, .png"
                  name="myfile"
                  @change="passportFrontChange"
                />
              </div>
              <div v-if="passportFront" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="passportFront">
                  <button type="button" class="m-10 outline-border">
                    View Passport
                  </button>
                </a>
              </div>
            </v-flex>
            <v-flex xs12 sm6 md3 v-if="this.countrySortNames != 'PAK'">
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Passport Back Image</button>
                <input
                  type="file"
                  name="myfile"
                  accept=".pdf, .jpeg, .jpg, .png"
                  @change="passportBackChange"
                />
              </div>
              <div v-if="passportBack" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="passportBack">
                  <button type="button" class="m-10 outline-border">
                    View Passport
                  </button>
                </a>
              </div>
            </v-flex>
            <v-flex xs12 sm6 md3 v-if="this.countrySortNames == 'PAK'">
              <div class="upload-btn-wrapper">
                <button class="btn">Upload CNIC Front Image</button>
                <input
                  type="file"
                  accept=".pdf, .jpeg, .jpg, .png"
                  name="myfile"
                  @change="CnicFrontChange"
                />
              </div>
              <div v-if="CNICFront" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="CNICFront">
                  <button type="button" class="m-10 outline-border">
                    View CNIC
                  </button>
                </a>
              </div>
            </v-flex>

            <v-flex xs12 sm6 md3 v-if="this.countrySortNames == 'PAK'">
              <div class="upload-btn-wrapper">
                <button class="btn">Upload CNIC Back Image</button>
                <input
                  type="file"
                  accept=".pdf, .jpeg, .jpg, .png"
                  name="myfile"
                  @change="CnicBackChange"
                />
              </div>
              <div v-if="CNICBack" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="CNICBack">
                  <button type="button" class="m-10 outline-border">
                    View CNIC
                  </button>
                </a>
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>

          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Password*</div>
              <div class="heading">
                <v-text-field
                  autocomplete="off"
                  label="Password *"
                  v-model="password"
                  type="password"
                  required
                  class="pt-0 currencyTitle"
                  single-line
                  :rules="[rules.password, rules.required]"
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row v-if="this.countrySortNames != 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">Trade Licence Number *</div>
              <div class="heading">
                <v-text-field
                  label="Trade Licence Number *"
                  v-model="tradeLicenseNumber"
                  required
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  single-line
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row v-if="this.countrySortNames != 'PAK'">
            <v-flex xs12 md4>
              <div
                v-if="type == 'Transporter'"
                class="subheading muted caption"
              >
                VAT Number
              </div>
              <div v-else class="subheading muted caption">VAT Number</div>
              <div class="heading">
                <v-text-field
                  :label="'VAT Number'"
                  v-model="vatNumber"
                  class="pt-0 currencyTitle"
                  single-line
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row v-if="this.countrySortNames != 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">Passport number*</div>
              <div class="heading">
                <v-text-field
                  autocomplete="off"
                  label="Passport Number *"
                  v-model="passport"
                  required
                  :rules="[rules.noWhiteSpace, rules.required]"
                  class="pt-0 currencyTitle"
                  single-line
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>

          <v-layout>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Secondary Email Id</div>
              <div class="heading">
                <v-combobox
                  v-model="model"
                  append-icon
                  hint="Maximum 3 emails"
                  label="Secondary Email Id"
                  multiple
                  single-line
                  chips
                  deletable-chips
                  class="tag-input pt-0 currencyTitle"
                  v-on:keyup.17="checkValue"
                  type="email"
                  small-chips
                ></v-combobox>
              </div>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex xs12 class="pr-1" sm6 md2>
              <div class="subheading muted caption">Country Code*</div>
              <div class="heading">
                <v-flex xs12 class="pr-4" sm6 md12>
                  <v-autocomplete
                    :rules="[rules.required]"
                    v-model="countryCode"
                    id="countryCode"
                    item-text="dialing_code"
                    item-value="dialing_code"
                    label="Country Code *"
                    :items="countryCodes"
                    persistent-hint
                    return-object
                    class="pt-0 currencyTitle"
                    single-line
                  ></v-autocomplete>
                </v-flex>
              </div>
            </v-flex>

            <v-flex xs12 class="pr-1" sm6 md2>
              <div class="subheading muted caption">Phone Number*</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="Phone Number*"
                  class="pt-0 currencyTitle"
                  :rules="[rules.phoneNumber, rules.required]"
                  v-model="phoneNo"
                ></v-text-field>
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Country*</div>
              <div class="heading">
                <v-text-field
                  readonly
                  label="Country*"
                  class="pt-0 currencyTitle"
                  v-model="countrySortNames"
                  :value="countrySortNames"
                  single-line
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 md4>
              <div class="subheading muted caption">
                Choose Transporter Type*
              </div>
              <div class="heading">
                <v-select
                  label="Choose Transporter Type*"
                  class="pt-0 currencyTitle"
                  :items="UserTypes"
                  v-model="UserType"
                  single-line
                  :rules="[rules.required]"
                ></v-select>
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="this.UserType == 1 && this.countrySortNames == 'UAE'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">All Banks Name</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="bankInfo"
                  item-text="bankName"
                  item-value="bankName"
                  v-model="bankName"
                  label="All Banks Name"
                  class="pt-0 currencyTitle"
                  single-line
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 md4 pl-3>
              <div class="subheading muted caption">Account Holder Name</div>
              <v-text-field
                class="pt-0 currencyTitle"
                v-model="bankHolderName"
                value="Transporter"
                label="Account Holder Name"
                :rules="[rules.onlyEnglish]"
                single-line
              ></v-text-field
            ></v-flex>

            <!-- <v-flex xs12 md4>
              <div class="subheading muted caption">All Transporter</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="transporters"
                  v-model="transporterId"
                  label="All Transporter*"
                  multiple
                  @change="!mainError ? (mainError = null) : ''"
                  single-line
                  class="pt-0 currencyTitle"
                  required
                ></v-autocomplete>
              </div>
            </v-flex> -->
          </v-layout>

          <v-layout v-if="this.countrySortNames == 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">Contact Person Name*</div>
              <div class="heading">
                <v-text-field
                  label="Contact Person Name  *"
                  v-model="ContactPersonName"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  required
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="this.countrySortNames == 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">NTN Number*</div>
              <div class="heading">
                <v-text-field
                  label="NTN Number *"
                  v-model="NTNNumber"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  required
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="this.countrySortNames == 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">STRN Number</div>
              <div class="heading">
                <v-text-field
                  label="STRN Number"
                  v-model="STRNNumber"
                  single-line
                  class="pt-0 currencyTitle"
                  required
                  counter
                  maxlength="13"
                />
              </div>
            </v-flex>
          </v-layout>

          <div
            class="my-3"
            v-if="haserror"
            style="width: 50%; margin: auto; text-align: center"
          >
            <v-alert v-model="haserror" class="py-2" outline color="red">{{
              x.error
            }}</v-alert>
          </div>

          <v-layout row>
            <v-spacer />
          </v-layout>

          <hr class="divider-rule" />
          <v-layout>
            <v-spacer />
            <v-btn
              size="large"
              color="grey darken-3"
              flat
              style="color: white"
              @click.native="close"
              >Cancel</v-btn
            >
            <v-btn
              size="large"
              color="orange darken-1"
              @click.native="check()"
              style="color: white"
              :loading="x.loading"
              >Register Transporter</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-card>

      <success-dialog
        content="Your transporter has been registered with us."
        :show="x.registerSuccess"
        :onclose="closeRegistration"
      />
    </v-container>
  </v-form>
</template>

<script>
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../event-bus.js";
import { gmapApi } from "vue2-google-maps";
import ErrorBox from "@/components/Common/ErrorBox";
import { getUserById } from "../../constants/api-urls";
import moment from "moment";
import { StorageKeys, UserTypes, countries } from "../../constants/constants";
export default {
  computed: {
    google: gmapApi,
  },

  components: {
    SuccessDialog,
    ErrorBox,
  },
  props: {
    profile: Object,
  },
  created() {
    this.fetchBank();
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.countrySortNames = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "transporter-profile-new") {
        this.$router.push(`/transporter`);
      }
    });
    this.UserTypes = UserTypes;
    this.countryCodes = countries;
    this.getUserById();
  },
  data() {
    return {
      userId: "",
      countrySortNames: "",
      multipleCountry: [],
      type: "Transporter",
      countryCodes: [],

      countryCode: "",
      UserType: "",
      UserTypes: [],
      STRNNumber: "",
      NTNNumber: "",
      tradeLicenseNumber: "",
      vatNumber: "",
      passport: "",
      ContactPersonName: "",
      valid: true,
      lazy: false,
      model: [],
      companyName: "",
      phoneNo: "",
      profilePicURL: "",
      tradeLicense: "",
      vatCertificate: "",
      passportFront: "",
      passportBack: "",
      CNICFront: "",
      CNICBack: "",
      username: "",
      secondaryEmail: [],
      emailId: "",
      password: "",
      processing: false,
      bankHolderName: "",
      bankName: "",
      bankInfo: [],
      x: {
        loading: false,
        registerSuccess: false,
        message: "",
        success: false,
        menu: false,
        error: null,
      },
      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        required: (value) => !!value || "This field is required.",
        onlyEnglish: (v) =>
          /^[a-zA-Z\s]*$/.test(v) || "Please Enter Valid value",
        password: (v) =>
          /^[a-zA-Z0-9]{4,}$/.test(v) ||
          "Password length must be of minimum 4 characters",
        NTNNumber: (v) =>
          /^([a-zA-Z0-9_-]){13}$/.test(v) || "Please Enter Valid Number",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No.",
        emailRules: (v) =>
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
            v
          ) || "E-mail must be valid",
        userName: (v) =>
          /^[a-z0-9_-]{6,15}$/.test(v) ||
          "Username must be of minimum 6 characters.",
        companyName: (v) =>
          /^[a-z0-9_-]{3,15}$/.test(v) || "Please Enter Valid company name.",
        intRule: (v) => {
          for (var i = 0; i < v.length; i++) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v[i])) {
              return "Insert valid email";
            }
            v[i] = parseInt(v[i]);
          }
          return true;
        },
      },
    };
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  watch: {
    model(val) {
      if (val.length > 3) {
        this.$nextTick(() => this.model.pop());
      }
    },
  },
  methods: {
    async check() {
      this.operationName = "create-transporter";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.register();
      } else {
        return;
      }
    },
    fetchBank() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      let { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/bankListForDriver`, body, config)
        .then(
          (response) => {
            let data = response.data.data;
            console.log(data);
            this.bankInfo = data;
            // data.forEach((element) => {
            //   this.bankInfo.push({
            //     text: element.bankName,
            //     value: element._id,
            //   });
            // });
            // console.log(this.banking.info);

            // this.bankName = response.data.data[2].bankName;
          },
          (error) => {
            this.error = "Something went wrong. Please try again later!";
            this.loading = false;
          }
        );
    },
    getUserById() {
      this.userId = localStorage.getItem("userId");
      this.loading = true;
      let url = getUserById;
      delete this.axios.defaults.headers.common["token"];
      let body = {
        userId: localStorage.getItem("userId"),
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          response.data.data.countrySortName.forEach((e) => {
            this.multipleCountry.push(e);
          });
        },
        (error) => {
          this.x.error = "Failed to fetch country";
        }
      );
    },
    tradeLicenseChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 1);
    },
    vatCertificateChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 2);
    },
    passportFrontChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 3);
    },
    passportBackChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 4);
    },
    CnicFrontChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 6);
    },
    CnicBackChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 7);
    },
    profileImageChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createProfileImage(files[0], 5);
    },
    createProfileImage(file, status) {
      this.x.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.$refs.fileUpload.value = "";
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg"
      ) {
        this.$refs.fileUpload.value = "";

        this.x.error = "Please upload proof of file type png , jpg or jpeg ";

        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file, status);
      }
    },

    createImage(file, status) {
      this.x.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.$refs.fileUpload.value = "";
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.$refs.fileUpload.value = "";

        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";

        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file, status);
      }
    },
    upload(file, statusChange) {
      this.x.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (statusChange == 1) {
              this.tradeLicense = response.data.data.original;
            } else if (statusChange == 2) {
              this.vatCertificate = response.data.data.original;
            } else if (statusChange == 3) {
              this.passportFront = response.data.data.original;
            } else if (statusChange == 4) {
              this.passportBack = response.data.data.original;
            } else if (statusChange == 5) {
              this.profilePicURL = response.data.data.original;
            } else if (statusChange == 6) {
              this.CNICFront = response.data.data.original;
            } else if (statusChange == 7) {
              this.CNICBack = response.data.data.original;
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.x.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.x.loading = false;
        }
      );
    },
    whiteSpace: function (a) {
      return a.toLowerCase().trim();
    },
    closeRegistration() {
      this.registerSuccess = false;
      this.$router.go(-1);
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
      this.$router.go(-1);
    },
    close() {
      this.$router.go(-1);
    },
    checkValue() {
      this.communicateDialog.search = "";
    },
    updateTags() {
      this.$nextTick(() => {
        this.communicateDialog.select.push(
          ...this.communicateDialog.search.split(",")
        );
        this.$nextTick(() => {
          this.communicateDialog.search = "";
        });
      });
    },
    closeRegistration() {
      this.registerSuccess = false;
      this.$router.go(-1);
    },

    register() {
      if (!navigator.onLine) {
        this.x.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }

      if (this.$refs.form.validate()) {
        if (this.processing) {
          return;
        } else {
          this.x.error = null;
          if (
            !this.tradeLicense &&
            this.UserType == 1 &&
            this.countrySortNames == "PAK"
          ) {
            this.x.error =
              "Please provide NTN certificate image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.tradeLicenseNumber &&
            this.UserType == 1 &&
            this.countrySortNames != "PAK"
          ) {
            this.x.error =
              "Please provide trade license number before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.tradeLicense &&
            this.UserType == 1 &&
            this.countrySortNames != "PAK"
          ) {
            this.x.error =
              "Please provide trade license image before moving ahead";
            this.x.loading = false;
            return false;
          }

          // if (
          //   !this.vatNumber &&
          //   this.UserType == 1 &&
          //   this.countrySortNames != "PAK" &&
          //   this.type == "Transporter"
          // ) {
          //   this.x.error = "Please provide VAT number before moving ahead";
          //   this.x.loading = false;
          //   return false;
          // }
          if (
            !this.vatCertificate &&
            this.UserType == 1 &&
            this.countrySortNames != "PAK" &&
            this.type == "Transporter"
          ) {
            this.x.error =
              "Please provide VAT certificate image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.passport &&
            this.UserType == 1 &&
            this.countrySortNames != "PAK"
          ) {
            this.x.error = "Please provide passport number before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.passportFront &&
            this.UserType == 1 &&
            this.countrySortNames != "PAK"
          ) {
            this.x.error =
              "Please provide passport front image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.passportBack &&
            this.UserType == 1 &&
            this.countrySortNames != "PAK"
          ) {
            this.x.error =
              "Please provide passport back image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.CNICFront &&
            this.UserType == 1 &&
            this.countrySortNames == "PAK"
          ) {
            this.x.error =
              "Please provide CNIC front image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.CNICBack &&
            this.UserType == 1 &&
            this.countrySortNames == "PAK"
          ) {
            this.x.error = "Please provide CNIC back image before moving ahead";
            this.x.loading = false;
            return false;
          }

          if (this.model.length) {
            let count_valid = 0;
            // let regex = '/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/';
            var re =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            for (let k = 0; k < this.model.length; k++) {
              this.model[k] = this.model[k].trim();

              if (re.test(this.model[k])) {
                count_valid = count_valid + 1;
              }
            }
            if (count_valid !== this.model.length) {
              this.x.error =
                "Please make sure all the emails are properly formed";

              this.x.loading = false;
              return false;
            }
          }

          this.x.error = null;
          this.processing = true;
          this.x.loading = true;

          let url = "/dmsAdmin/addTransporter";
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };

          let body = {
            companyName: this.companyName.trim(),
            userName: this.username.trim(),
            jobType: this.UserType,
            password: this.password.trim(),
            emailId: this.emailId.trim(),
            secondaryEmail: this.model,
            phoneNo: this.phoneNo,
            workingCountry: this.countrySortNames,
            countryCode: this.countryCode.dialing_code,
            transporterType: this.type,
          };
          if (this.bankName) {
            body.bankName = this.bankName;
          }
          if (this.bankHolderName) {
            if (this.bankHolderName.trim()) {
              body.bankHolderName = this.bankHolderName.trim();
            }
          }
          if (this.countrySortNames == "PAK") {
            body.NTNNumber = this.NTNNumber.trim();
            if (this.STRNNumber || this.STRNNumber != null) {
              body.STRNNumber = this.STRNNumber;
            }
            body.ContactPersonName = this.ContactPersonName.trim();
            if (this.CNICFront) {
              body.cnicFrontImage = this.CNICFront;
            }
            if (this.CNICBack) {
              body.cnicBackImage = this.CNICBack;
            }
          }

          if (this.UserType == 1) {
            body.tradeLicense = this.tradeLicense;
            if (this.countrySortNames != "PAK") {
              body.tradeLicenseNumber = this.tradeLicenseNumber;
              body.passportNumber = this.passport;
              if (this.vatNumber.trim()) {
                body.vatNumber = this.vatNumber;
              }
            }
            if (this.vatCertificate) {
              body.vatCertificate = this.vatCertificate;
            }
            if (this.passportFront) {
              body.passportFront = this.passportFront;
            }
            if (this.passportBack) {
              body.passportBack = this.passportBack;
            }
          }
          if (this.UserType == 2 || this.UserType == 3) {
            if (this.countrySortNames != "PAK") {
              if (this.tradeLicenseNumber) {
                body.tradeLicenseNumber = this.tradeLicenseNumber;
              }
              if (this.vatNumber.trim()) {
                body.vatNumber = this.vatNumber;
              }
              if (this.passport) {
                body.passportNumber = this.passport;
              }
            }
            if (this.tradeLicense) {
              body.tradeLicense = this.tradeLicense;
            }
            if (this.vatCertificate) {
              body.vatCertificate = this.vatCertificate;
            }
            if (this.passportFront) {
              body.passportFront = this.passportFront;
            }
            if (this.passportBack) {
              body.passportBack = this.passportBack;
            }
          }

          if (this.profilePicURL) {
            body.profilePicURL = this.profilePicURL;
          }

          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              if (response.status === 200) {
                this.x.registerSuccess = true;
                this.items = response.data.data;

                this.x.registerSuccess = true;
                this.processing = false;
              } else {
                this.processing = false;
                this.x.error = "Failed to add transporter";
              }

              this.x.loading = false;
            },
            (error) => {
              this.x.loading = false;
              this.processing = false;
              this.x.error = error.response.data.message;
            }
          );
        }
      } else {
        this.x.error = "Please fill all required fields in above form! ";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu,
.menu__content {
  background: white !important;
}
.outline-border {
  border: none;
  outline: none;
}
.thumb-img {
  border: 2px solid darkorange;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}
</style>
